import getQuestions from '@proxies/ia/getQuestions'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import CardGrid from '../CardGrid'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import Navbar from '../Navbar'
import type { Estancias } from '@components/inspiration/InspirationPage'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
import IncidentModal from '@components/IncidentModal'
import subir from '@assets/subir-imagen.png'
import uploadImage from '@proxies/ia/uploadImage'

export default function RedecorationPage() {
  const { creditosActuales } = useWebsocketContext()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const texto = store((s) => s.translations)
  const [, setImageDimensions] = useState({ width: 0, height: 0 })
  const [scaleFactor, setScaleFactor] = useState(1)

  function t(t: string) {
    return texto[t] ?? t
  }
  const navigate = useNavigate()
  const location = useLocation()

  const { img, imageWithDrawing } = location.state || {}

  const [uploadedImage, setUploadedImage] = useState<string | null>(img || null)
  const [drawingDataUrl, setDrawingDataUrl] = useState<string | null>(imageWithDrawing || null)

  const [, setImageFile] = useState('')
  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const estancias = useLoaderData() as Estancias
  const [, setImageSrc] = useState('')
  const [, setBlackImageURL] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDrawing, setIsDrawing] = useState(false)
  const [penSize, setPenSize] = useState(70)
  const [history, setHistory] = useState<string[]>([])
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const drawingCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const canvasWidth = 800
  const canvasHeight = 500

  async function resizeImage(
    imageSrc: string,
    maxWidth: number,
    maxHeight: number,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = imageSrc

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height)
        const width = img.width * scaleFactor
        const height = img.height * scaleFactor

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0, width, height)

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedImage = URL.createObjectURL(blob)
            resolve(resizedImage)
          } else {
            reject(new Error('Error al redimensionar la imagen'))
          }
        }, 'image/png')
      }

      img.onerror = reject
    })
  }

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  useEffect(() => {
    if (uploadedImage) {
      const canvas = canvasRef.current
      const drawingCanvas = drawingCanvasRef.current

      if (canvas && drawingCanvas) {
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.src = uploadedImage

        img.onload = () => {
          const scale = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const adjustedWidth = img.width * scale
          const adjustedHeight = img.height * scale

          setScaleFactor(scale)
          setImageDimensions({ width: adjustedWidth, height: adjustedHeight })

          drawingCanvas.width = adjustedWidth
          drawingCanvas.height = adjustedHeight
          canvas.width = adjustedWidth
          canvas.height = adjustedHeight

          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(img, 0, 0, adjustedWidth, adjustedHeight)
          }
        }
      }
    }
  }, [uploadedImage])

  useEffect(() => {
    if (uploadedImage) {
      const canvas = canvasRef.current
      const drawingCanvas = drawingCanvasRef.current

      if (canvas && drawingCanvas) {
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.src = uploadedImage

        img.onload = () => {
          const scaleFactor = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const imageWidth = img.width * scaleFactor
          const imageHeight = img.height * scaleFactor
          drawingCanvas.width = imageWidth
          drawingCanvas.height = imageHeight
          canvas.width = imageWidth
          canvas.height = imageHeight

          ctx?.clearRect(0, 0, canvas.width, canvas.height)
          ctx?.drawImage(img, 0, 0, imageWidth, imageHeight)

          if (drawingDataUrl) {
            const overlayCtx = drawingCanvas.getContext('2d')
            const overlayImage = new Image()
            overlayImage.src = drawingDataUrl
            overlayImage.onload = () => {
              overlayCtx?.drawImage(overlayImage, 0, 0, imageWidth, imageHeight)
            }
          }
        }
      }
    }
  }, [uploadedImage, drawingDataUrl])

  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)

  const lockScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const unlockScroll = () => {
    document.body.style.overflow = 'auto'
  }

  const handleMouseLeave = () => {
    setCursorPosition(null)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isMobileDevice) return
    const rect = drawingCanvasRef.current?.getBoundingClientRect()
    if (rect) {
      setCursorPosition({
        x: (e.clientX - rect.left) / scaleFactor,
        y: (e.clientY - rect.top) / scaleFactor,
      })
    }
  }

  const startDrawing = (e: React.MouseEvent | React.TouchEvent) => {
    if (!drawingCanvasRef.current) return
    const ctx = drawingCanvasRef.current.getContext('2d')
    const canvas = drawingCanvasRef.current
    const rect = canvas.getBoundingClientRect()

    if (ctx) {
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height

      const { offsetX, offsetY } =
        'touches' in e
          ? getTouchPosition(e)
          : {
              offsetX: (e.nativeEvent.clientX - rect.left) * scaleX,
              offsetY: (e.nativeEvent.clientY - rect.top) * scaleY,
            }

      ctx.globalCompositeOperation = 'xor'
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.6)'
      ctx.lineWidth = penSize
      ctx.lineCap = 'round'
      ctx.beginPath()
      ctx.moveTo(offsetX, offsetY)
      setIsDrawing(true)

      lockScroll()
    }
  }

  const draw = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || !drawingCanvasRef.current) return
    const ctx = drawingCanvasRef.current.getContext('2d')
    const canvas = drawingCanvasRef.current
    const rect = canvas.getBoundingClientRect()

    if (ctx) {
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height

      const { offsetX, offsetY } =
        'touches' in e
          ? getTouchPosition(e)
          : {
              offsetX: (e.nativeEvent.clientX - rect.left) * scaleX,
              offsetY: (e.nativeEvent.clientY - rect.top) * scaleY,
            }

      ctx.lineTo(offsetX, offsetY)
      ctx.stroke()
      ctx.beginPath()
      ctx.moveTo(offsetX, offsetY)
    }
  }

  const stopDrawing = () => {
    if (isDrawing) {
      setIsDrawing(false)
      const canvas = drawingCanvasRef.current
      if (canvas) {
        const canvasDataUrl = canvas.toDataURL()
        setHistory((prevHistory) => [...prevHistory, canvasDataUrl])
      }
      unlockScroll()
    }
  }
  const getTouchPosition = (e: React.TouchEvent) => {
    const rect = drawingCanvasRef.current?.getBoundingClientRect()
    const touch = e.touches[0]
    if (rect && touch) {
      const scaleX = drawingCanvasRef.current!.width / rect.width
      const scaleY = drawingCanvasRef.current!.height / rect.height
      return {
        offsetX: (touch.clientX - rect.left) * scaleX,
        offsetY: (touch.clientY - rect.top) * scaleY,
      }
    }
    return { offsetX: 0, offsetY: 0 }
  }

  const undoLastAction = () => {
    if (history.length > 0) {
      const canvas = drawingCanvasRef.current
      const ctx = canvas?.getContext('2d')
      const newHistory = [...history]
      newHistory.pop()

      if (canvas && ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        const lastState = newHistory[newHistory.length - 1]
        if (lastState) {
          const img = new Image()
          img.src = lastState
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          }
        }
        setHistory(newHistory)
      }
    }
  }

  const clearCanvas = () => {
    const drawingCanvas = drawingCanvasRef.current
    const canvas = canvasRef.current
    if (canvas && uploadedImage) {
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.src = uploadedImage

      img.onload = () => {
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          const scaleFactor = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const imageWidth = img.width * scaleFactor
          const imageHeight = img.height * scaleFactor
          const xOffset = (canvas.width - imageWidth) / 2
          const yOffset = (canvas.height - imageHeight) / 2
          ctx.drawImage(img, xOffset, yOffset, imageWidth, imageHeight)
        }
      }
    }

    if (drawingCanvas) {
      const ctx = drawingCanvas.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height)
      }
    }
  }

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedTypes.includes(file.type)) {
        openModal(t('MSG_487'))
        return
      }
      setLoading(true)
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const resizedImage = await resizeImage(e.target?.result as string, 1440, 808)
          setUploadedImage(resizedImage)
          setDrawingDataUrl(null)
        }
        reader.readAsDataURL(file)
        const fileName = await uploadImage(file)
        setImageSrc(URL.createObjectURL(file))
        setImageFile(fileName)
      } catch (error) {
        console.error('Error uploading image:', error)
        openModal(t('MSG_533'))
      } finally {
        setLoading(false)
      }
    }
  }

  const generateBlackImage = async (): Promise<string> => {
    const drawingCanvas = drawingCanvasRef.current

    if (drawingCanvas && uploadedImage) {
      const img = new Image()
      img.src = uploadedImage

      return new Promise((resolve, reject) => {
        img.onload = async () => {
          const blackCanvas = document.createElement('canvas')
          blackCanvas.width = img.width
          blackCanvas.height = img.height
          const ctx = blackCanvas.getContext('2d')

          if (ctx) {
            ctx.fillStyle = '#000000'
            ctx.fillRect(0, 0, blackCanvas.width, blackCanvas.height)
            ctx.drawImage(drawingCanvas, 0, 0, img.width, img.height)

            const imageData = ctx.getImageData(0, 0, blackCanvas.width, blackCanvas.height)
            const data = imageData.data

            for (let i = 0; i < data.length; i += 4) {
              if (data[i] !== 0 || data[i + 1] !== 0 || data[i + 2] !== 0) {
                data[i] = 255
                data[i + 1] = 255
                data[i + 2] = 255
              }
            }

            ctx.putImageData(imageData, 0, 0)

            blackCanvas.toBlob((blob) => {
              if (blob) {
                const blackImageURL = URL.createObjectURL(blob)
                setBlackImageURL(blackImageURL)
                resolve(blackImageURL)
              } else {
                reject('Error creando blob para la imagen en blanco y negro')
              }
            }, 'image/png')
          } else {
            reject('Error accediendo al contexto del canvas')
          }
        }

        img.onerror = () => reject('Error al cargar la imagen original')
      })
    }

    return Promise.reject('No se encontraron canvas de dibujo o imagen cargada')
  }

  const openModal = (message: string) => {
    setModalMessage(message)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const getDrawingCanvasDataUrl = (): string | null => {
    const drawingCanvas = drawingCanvasRef.current
    return drawingCanvas ? drawingCanvas.toDataURL('image/png') : null
  }

  async function handleCardClick(index: number, id: number) {
    if (!uploadedImage) {
      openModal(t('MSG_489'))
      return
    }

    const drawingCanvasDataUrl = getDrawingCanvasDataUrl()
    setSelectedCard(index)

    const resizedUploadedImage = await resizeImage(uploadedImage, 1440, 808)

    let imageFile = ''
    if (resizedUploadedImage) {
      const originalBlob = await fetch(resizedUploadedImage).then((res) => res.blob())
      const originalFile = new File([originalBlob], 'imagen-resized-original.png', {
        type: 'image/png',
      })
      imageFile = await uploadImage(originalFile)
    }

    const blackImageURL = await generateBlackImage()
    const resizedBlackImage = blackImageURL ? await resizeImage(blackImageURL, 1440, 808) : ''

    let blackImageFile = ''
    if (resizedBlackImage) {
      const blackBlob = await fetch(resizedBlackImage).then((res) => res.blob())
      const blackFile = new File([blackBlob], 'black-drawing-resized.png', { type: 'image/png' })
      blackImageFile = await uploadImage(blackFile)
    }

    const nextPageInfo = await getQuestions(() => {}, id)
    const selectedEstancia = estancias[index]

    navigate('/redecoration/styles', {
      state: {
        respuestas: nextPageInfo,
        texto: selectedEstancia?.texto,
        concatIds: [id],
        img: resizedUploadedImage,
        imageFile,
        blackImageFile,
        imageWithDrawing: drawingCanvasDataUrl,
      },
    })

    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <div className='relative flex flex-col items-center justify-center bg-white text-black'>
        <div className='absolute inset-0 top-0 z-0 h-[500px] w-full bg-black'></div>
        <h1 className='z-20 ml-8 mt-32 self-start font-montserrat-bold text-4xl text-white'>
          {t('MSG_68')}
        </h1>
        <div className='relative z-10 mt-4 flex w-full max-w-3xl flex-col items-center border border-main bg-white text-center'>
          <div className='relative flex w-full items-center justify-center'>
            {!uploadedImage ? (
              <div className='flex h-96 w-full flex-col items-center justify-center'>
                <img
                  src={subir}
                  alt='Subir imagen'
                  className='mb-2 h-24'
                />
                {loading ? (
                  <p className='text-md font-medium'>{t('MSG_488')}</p>
                ) : (
                  <>
                    <p className='text-md font-medium'>{t('MSG_143')}</p>
                    <p className='mb-6 w-3/5 text-sm text-gray-500'>{t('MSG_142')}</p>
                  </>
                )}
                <input
                  type='file'
                  className='mt-4 hidden'
                  id='file-upload'
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <label
                  htmlFor='file-upload'
                  className='mt-2 cursor-pointer rounded-lg bg-main px-20 py-1.5 text-white'
                >
                  {t('MSG_144')}
                </label>
              </div>
            ) : (
              <div className='relative flex items-center justify-center'>
                <canvas
                  ref={canvasRef}
                  className='mx-auto max-h-[500px] max-w-full object-contain'
                  width={800}
                  height={500}
                />
                <canvas
                  ref={drawingCanvasRef}
                  onMouseDown={startDrawing}
                  onMouseMove={(e) => {
                    handleMouseMove(e)
                    draw(e)
                  }}
                  onMouseUp={stopDrawing}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={startDrawing}
                  onTouchMove={draw}
                  onTouchEnd={stopDrawing}
                  className='absolute mx-auto max-h-[500px] max-w-full cursor-cell object-contain'
                  width={800}
                  height={500}
                />
                {cursorPosition && (
                  <div
                    style={{
                      position: 'absolute',
                      left: cursorPosition.x * scaleFactor - penSize / 2,
                      top: cursorPosition.y * scaleFactor - penSize / 2,
                      width: penSize,
                      height: penSize,
                      borderRadius: '50%',
                      border: '1px solid rgba(255, 255, 255, 0.5)',
                      background: 'rgba(0, 0, 0, 0.3)',
                      pointerEvents: 'none',
                    }}
                    className='z-50'
                  />
                )}
              </div>
            )}
          </div>
          {uploadedImage && (
            <div className='my-4 flex flex-col items-center'>
              <div className='flex w-full flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'>
                <input
                  type='file'
                  className='hidden rounded bg-main p-2 text-white'
                  id='file-upload-reload'
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <label
                  htmlFor='file-upload-reload'
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_144')}
                </label>
                <button
                  onClick={clearCanvas}
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_510')}
                </button>
                <button
                  onClick={undoLastAction}
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_509')}
                </button>
                <div className='flex items-center sm:ml-auto'>
                  <label className='mr-1'>{t('MSG_511')}</label>
                  <input
                    type='range'
                    min='30'
                    max='100'
                    value={penSize}
                    onChange={(e) => setPenSize(Number(e.target.value))}
                    className='custom-pen-range'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <IncidentModal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          message={modalMessage}
          onCloseNavigate={closeModal}
        />
      </div>
      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_111')}</h1>
      <p className='-mt-6 mb-4 font-light'>{t('MSG_139')}</p>
      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {estancias &&
            estancias
              .filter((estancia) => estancia.idGrupoPreguntasIa !== 214)
              .map((estancia, index) => (
                <div
                  key={index}
                  className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                >
                  <CardGrid
                    imageUrl={estancia.imagenUrl1}
                    text={estancia.texto}
                    isSelected={selectedCard === index}
                    onClick={async () => handleCardClick(index, estancia.idGrupoPreguntasIa)}
                    acceso={estancia.acceso}
                    // WithoutImage={imageFile === ''}
                    WithoutImage={!uploadedImage}
                  />
                </div>
              ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
