import { useEffect } from 'react'
import t from '../store/languageActions'
import { useWebsocketContext } from './Context'
import Footer from './Footer'
import Navbar from './Navbar'
import nosotros from '@assets/nosotros.png'
import { ScrollProgressBar } from './ScrollProgressBar'

export default function AboutUs() {
  const { creditosActuales } = useWebsocketContext()

  useEffect(() => {
    // Agrega animaciones en la carga de la página
    const sections = document.querySelectorAll('.fade-in-section')
    const options = {
      threshold: 0.25,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible')
        }
      })
    }, options)

    sections.forEach((section) => {
      observer.observe(section)
    })
  }, [])

  return (
    <>
      <div className='bg-black text-white'>
        <ScrollProgressBar />
        <Navbar
          imgRedirectHome={true}
          creditosActuales={creditosActuales}
        />
        <div className='h-14 w-full bg-black'></div>
        <div className='relative w-full overflow-hidden'>
          <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
            <h1 className='ml-4 mt-8 text-left font-montserrat-bold text-3xl font-bold md:text-5xl lg:-ml-6'>
              {t('MSG_197')}
            </h1>
          </div>
          <section className='relative mb-12 mt-6 px-4 text-left font-chopin md:px-24 lg:px-44'>
            <p className='text-lg md:text-xl'>{t('MSG_198')}</p>
          </section>
          <img
            src={nosotros}
            alt='Nosotros'
            className='h-96 w-full object-cover'
          />
        </div>
        <section className='fade-in-section relative my-12 px-4 md:px-24 lg:px-44'>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_199')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_200')} </p>
            </div>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_201')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_202')} </p>
            </div>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_203')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_204')}</p>
            </div>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_205')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_206')}</p>
            </div>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_207')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_208')}</p>
            </div>
            <div className='flex transform flex-col items-start p-4 transition-transform duration-500 hover:scale-105'>
              <span className='font-creato-bold text-xl text-main'>{t('MSG_209')}</span>
              <p className='text-left font-chopin text-lg'>{t('MSG_210')} </p>
            </div>
          </div>
        </section>

        {/* Cierre / Final */}
        <section className='relative my-12 px-4 text-center md:px-24 lg:px-44'>
          <p className='text-left font-chopin text-lg'>{t('MSG_215')}</p>
          <p className='mt-20 font-creato-bold text-xl text-main'>{t('MSG_216')}</p>
        </section>
        <div className='h-14 w-full bg-black'></div>
      </div>
      <Footer />
    </>
  )
}
