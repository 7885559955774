import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import { motion } from 'framer-motion'

export default function PremiumPlans() {
  const navigate = useNavigate()
  const token = store((s) => s.userToken)

  const [planType, setPlanType] = useState<'monthly' | 'yearly'>('monthly')

  const monthlyPlans = [
    {
      title: `${t('MSG_180')}`,
      description: [t('MSG_45'), t('MSG_47')],
      price: `${t('MSG_165')}€`,
      credits: `${t('MSG_166')}`,
      highlighted: false,
      time: `${t('MSG_220')}`,
    },
    {
      title: `${t('MSG_424')}`,
      description: [t('MSG_45'), t('MSG_46')],
      price: `${t('MSG_178')}€`,
      credits: `${t('MSG_179')}`,
      highlighted: true,
      time: `${t('MSG_220')}`,
    },
  ]

  const yearlyPlans = [
    {
      title: `${t('MSG_180')}`,
      description: [t('MSG_476'), t('MSG_47')],
      price: `${t('MSG_477')}€`,
      credits: `${t('MSG_478')}`,
      highlighted: false,
      time: `${t('MSG_479')}`,
    },
    {
      title: `${t('MSG_424')}`,
      description: [t('MSG_476'), t('MSG_46')],
      price: `${t('MSG_480')}€`,
      credits: `${t('MSG_481')}`,
      highlighted: true,
      time: `${t('MSG_479')}`,
    },
  ]

  const plans = planType === 'monthly' ? monthlyPlans : yearlyPlans

  const handleButtonClick = () => {
    if (token) {
      navigate('/user/plans')
      window.scrollTo(0, 0)
    } else {
      navigate('/register')
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className='bg-black py-10 text-white'>
      <div className='mb-10 flex items-center justify-center'>
        <h1 className='text-center text-2xl font-bold text-white'>{t('MSG_489')}</h1>
      </div>
      <div className='container mx-auto px-4'>
        {/* Contenedor para el texto y el toggle */}
        <div className='mb-12 flex flex-col items-center justify-center space-y-4 lg:flex-row lg:space-x-28 lg:space-y-0'>
          <h2 className='font-creato-bold text-2xl leading-loose tracking-tight text-white'>
            {t('MSG_44')}
          </h2>

          {/* Toggle para seleccionar entre mensual y anual */}
          <div className='relative flex w-[250px] items-center rounded-md bg-gray-200 p-1'>
            <motion.div
              className='absolute bottom-1 left-1 top-1 h-10 w-28 rounded-md bg-main'
              initial={false}
              animate={{ x: planType === 'monthly' ? 0 : 122 }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            />
            <button
              onClick={() => setPlanType('monthly')}
              className={`z-10 w-1/2 py-2 text-center ${planType === 'monthly' ? 'text-white' : 'text-black'}`}
            >
              {t('MSG_482')}
            </button>
            <button
              onClick={() => setPlanType('yearly')}
              className={`z-10 w-1/2 py-2 text-center ${planType === 'yearly' ? 'text-white' : 'text-black'}`}
            >
              {t('MSG_483')}
            </button>
          </div>
        </div>

        {/* Renderizado de los planes */}
        <div className='flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0'>
          {plans.map((plan, index) => (
            <div
              key={index}
              className='relative flex w-full flex-col items-center bg-white p-6 text-black shadow-lg lg:w-72'
            >
              <div
                className={`absolute -top-4 left-4 rounded-md border border-gray-300 px-3 py-1 text-sm font-bold shadow-lg ${
                  plan.highlighted ? 'bg-main text-white' : 'bg-main text-white'
                }`}
              >
                {plan.title}
              </div>
              <div className='mb-4 mt-8 text-xl'>
                {plan.description.map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
              </div>
              <p className='mb-4 text-4xl font-bold'>
                {plan.price}{' '}
                <span className='-ml-2 font-creato-light text-[26px]'>{plan.time}</span>
              </p>
              <p className='mb-6 text-black'>{plan.credits}</p>
              <p className='-mt-2 mb-6 text-sm text-black'>{t('MSG_41')}</p>
              <button
                onClick={handleButtonClick}
                className='transform rounded-md bg-black px-4 py-2 text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
              >
                {t('MSG_75')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
