import getQuestions from '@proxies/ia/getQuestions'
import { useLayoutEffect, useState } from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import CardGrid from '../CardGrid'
import Footer from '../Footer'
import ImageOptions from '../ImageOptions'
import Navbar from '../Navbar'
import { useWebsocketContext } from '../Context'
import type { Estancias } from '@components/inspiration/InspirationPage'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function DecorationPage() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const { creditosActuales } = useWebsocketContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { img } = location.state || {}

  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const estancias = useLoaderData() as Estancias
  const [imgSrc, setImageSrc] = useState(img || '')
  const [imageFile, setImageFile] = useState('')

  const [isChecked, setIsChecked] = useState(true)

  useLayoutEffect(() => {
    if (!token) navigate('/')
    if (img) {
      setImageSrc(img)
    }
  }, [token, navigate, img])

  async function handleCardClick(index: number, id: number) {
    setSelectedCard(index)
    const nextPageInfo = await getQuestions(() => {}, id)
    const selectedEstancia = estancias[index]
    navigate('/decoration/styles', {
      state: {
        respuestas: nextPageInfo,
        texto: selectedEstancia?.texto,
        concatIds: [id],
        img: imgSrc,
        imageFile,
        isChecked: isChecked,
      },
    })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_70')}
        imgSrc={imgSrc}
        isUpload={true}
        setImageSrc={setImageSrc}
        setImageFile={setImageFile}
      />

      {/* Check para controlar el tipo de trabajo */}
      <div className='my-3 text-center md:mr-96'>
        <label className='flex cursor-pointer items-center justify-center'>
          <input
            type='checkbox'
            id='renderizado'
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className='peer sr-only'
          />
          {/* Custom checkbox */}
          <div className='flex h-6 w-6 items-center justify-center rounded-md border-2 border-main bg-white transition-all duration-200 peer-checked:bg-main md:h-5 md:w-5'>
            <svg
              className={`h-4 w-4 text-white md:h-5 md:w-5 ${isChecked ? 'block' : 'hidden'}`}
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M5 13l4 4L19 7'
              ></path>
            </svg>
          </div>
          <label
            htmlFor='renderizado'
            className='ml-2 select-none font-light'
          >
            {t('MSG_507')}
          </label>
        </label>
      </div>
      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_111')}</h1>
      <p className='-mt-6 mb-4 font-light'>{t('MSG_139')}</p>
      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {estancias &&
            estancias
              .filter((estancia) => estancia.idGrupoPreguntasIa !== 214)
              .map((estancia, index) => (
                <div
                  key={index}
                  className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                >
                  <CardGrid
                    imageUrl={estancia.imagenUrl1}
                    text={estancia.texto}
                    isSelected={selectedCard === index}
                    onClick={async () => handleCardClick(index, estancia.idGrupoPreguntasIa)}
                    acceso={estancia.acceso}
                    WithoutImage={imageFile === img}
                  />
                </div>
              ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
