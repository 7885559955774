import getQuestions from '@proxies/ia/getQuestions'
import { useLayoutEffect, useState } from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import CardGrid from '../CardGrid'
import Footer from '../Footer'
import ImageOptions from '../ImageOptions'
import Navbar from '../Navbar'
import { useWebsocketContext } from '../Context'
import type { Estancias } from '@components/inspiration/InspirationPage'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function RenderingPage() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const { creditosActuales } = useWebsocketContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { img } = location.state || {}
  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const estancias = useLoaderData() as Estancias
  const [imgSrc, setImageSrc] = useState(img || '')
  const [imageFile, setImageFile] = useState('')

  useLayoutEffect(() => {
    if (!token) navigate('/')
    if (img) {
      setImageSrc(img)
    }
  }, [token, navigate, img])

  async function handleCardClick(index: number, id: number) {
    setSelectedCard(index)
    const nextPageInfo = await getQuestions(() => {}, id)
    const selectedEstancia = estancias[index]
    navigate('/rendering/styles', {
      state: {
        respuestas: nextPageInfo,
        texto: selectedEstancia?.texto,
        concatIds: [id],
        img: imgSrc,
        imageFile,
      },
    })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_456')}
        imgSrc={imgSrc}
        isUpload={true}
        setImageSrc={setImageSrc}
        setImageFile={setImageFile}
      />
      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_111')}</h1>
      <p className='-mt-6 mb-4 font-light'> {t('MSG_139')}</p>
      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {estancias &&
            estancias
              .filter((estancia) => estancia.idGrupoPreguntasIa !== 214) // Filtrar la estancia con id 214
              .map((estancia, index) => (
                <div
                  key={index}
                  className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                >
                  <CardGrid
                    imageUrl={estancia.imagenUrl1}
                    text={estancia.texto}
                    isSelected={selectedCard === index}
                    onClick={async () => handleCardClick(index, estancia.idGrupoPreguntasIa)}
                    acceso={estancia.acceso}
                    WithoutImage={imageFile === img}
                  />
                </div>
              ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
