import atras from '@assets/atras.png'
import { useNavigate } from 'react-router-dom'
import t from '../store/languageActions'
type AlertInfoProps = {
  message?: string
  isSpecial?: boolean
  navigateTo: string
  state?: any
  fn?: () => void
}
export default function AlertInfo({ message, isSpecial, navigateTo, state, fn }: AlertInfoProps) {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate(navigateTo, { state })
  }

  return (
    <div
      className={`relative mb-8 mt-2 flex items-center justify-center px-0.5 lg:px-3 ${isSpecial ? 'special-class' : ''}`}
    >
      <div
        className={`relative flex ${isSpecial ? 'w-full lg:w-[81.1%]' : 'w-full max-w-3xl'} items-center border border-gray-500 bg-white p-2.5 text-base leading-5 text-black shadow-lg`}
      >
        <button
          onClick={fn ? fn : handleNavigate}
          className='absolute left-3'
          aria-label='Go Back'
        >
          <img
            src={atras}
            alt='Return'
            className='h-5'
          />
        </button>
        <div className='ml-10 text-start'>
          <span className='font-creato-bold'>{t('MSG_116')}</span> {message}
        </div>
      </div>
    </div>
  )
}
