import estrella from '@assets/estrella.png'
import subir from '@assets/subir-imagen.png'
import { useState, useRef, useEffect } from 'react'
import uploadImage from '../proxies/ia/uploadImage'
import { store } from '../store'
import IncidentModal from './IncidentModal'
type ImageOptionsProps = {
  title: string
  subtitle?: string
  buttonText?: string
  imgSrc: string
  imageWithDrawing?: string
  onButtonClick?: () => void
  isUpload?: boolean
  setImageSrc?: (src: string) => void
  setImageFile?: (id: string) => void
}
export default function ImageOptions({
  title,
  subtitle,
  buttonText,
  imgSrc,
  imageWithDrawing,
  onButtonClick,
  isUpload = false,
  setImageSrc,
  setImageFile,
}: ImageOptionsProps) {
  const texto = store((s) => s.translations)
  function t(t: string) {
    return texto[t] ?? t
  }
  const [uploadedImage, setUploadedImage] = useState<string | null>(imgSrc || null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  function openModal(message: string) {
    setModalMessage(message)
    setModalOpen(true)
  }
  function closeModal() {
    setModalOpen(false)
  }

  useEffect(() => {
    setUploadedImage(imgSrc)
  }, [imgSrc])

  function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      const reader = new FileReader()
      reader.onload = (e) => {
        img.src = e.target?.result as string
        img.onload = () => {
          const originalWidth = img.width
          const originalHeight = img.height
          // console.log(`Original image dimensions: ${originalWidth}x${originalHeight}`)
          let width = originalWidth
          let height = originalHeight
          if (originalWidth > maxWidth || originalHeight > maxHeight) {
            const widthScale = maxWidth / originalWidth
            const heightScale = maxHeight / originalHeight
            const scale = Math.min(widthScale, heightScale)
            width = Math.floor(originalWidth * scale)
            height = Math.floor(originalHeight * scale)
          }
          console.log(`Resized image dimensions: ${width}x${height}`)
          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height)
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const resizedFile = new File([blob], file.name, { type: file.type })
                  resolve(resizedFile)
                } else {
                  reject(new Error('Error resizing image'))
                }
              },
              file.type,
              0.7,
            )
          }
        }
      }
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedTypes.includes(file.type)) {
        openModal(t('MSG_487'))
        return
      }
      setLoading(true)

      try {
        file = await resizeImage(file, 1440, 808)
        console.log('Resized file:', file)
        const reader = new FileReader()
        reader.onload = (e) => {
          setUploadedImage(e.target?.result as string)
        }
        reader.readAsDataURL(file)
        const blobImageSrc = window.URL.createObjectURL(file)
        if (setImageSrc) {
          setImageSrc(blobImageSrc)
        }
        const fileName = await uploadImage(file)
        if (setImageFile) {
          setImageFile(fileName)
        }
        console.log('Uploaded file name:', fileName)
      } catch (error) {
        console.error('Error uploading image:', error)
        openModal(t('MSG_533'))
      } finally {
        setLoading(false)
      }
    }
  }
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const imageToDisplay = uploadedImage || imgSrc

  return (
    <div className='relative flex flex-col items-center justify-center bg-white text-black'>
      <div className='absolute inset-0 top-0 z-0 h-[500px] w-full bg-black'></div>
      <h1 className='z-20 ml-8 mt-32 self-start font-montserrat-bold text-4xl text-white'>
        {title}
      </h1>
      <div className='relative z-10 mt-4 flex w-full max-w-3xl flex-col items-center border border-main bg-white text-center'>
        <div className='relative w-full'>
          {isUpload && !uploadedImage ? (
            <div className='flex h-96 w-full flex-col items-center justify-center'>
              <img
                src={subir}
                alt='Increase'
                className='mb-2 h-24'
              />
              {loading ? (
                <p className='text-md font-medium'>{t('MSG_488')}</p>
              ) : (
                <>
                  <p className='text-md font-medium'>{t('MSG_143')}</p>
                  <p className='mb-6 w-3/5 text-sm text-gray-500'>{t('MSG_142')}</p>
                </>
              )}
              <input
                type='file'
                className='mt-4 hidden'
                id='file-upload'
                ref={fileInputRef}
                onChange={handleImageUpload}
              />
              <label
                htmlFor='file-upload'
                className='mt-2 cursor-pointer rounded-lg bg-main px-20 py-1.5 text-white'
              >
                {t('MSG_144')}
              </label>
            </div>
          ) : (
            <div className='relative w-full'>
              <img
                src={imageToDisplay}
                alt={title}
                className='h-96 w-full object-contain'
              />
              {imageWithDrawing && (
                <img
                  src={imageWithDrawing}
                  alt='Overlay Drawing'
                  className='absolute left-0 top-0 h-96 w-full object-contain'
                  style={{ pointerEvents: 'none' }}
                />
              )}
            </div>
          )}
          {!isUpload && (
            <div className='absolute inset-0 flex flex-col items-center justify-center p-4'>
              <p className='font-chopin text-lg text-white'>{subtitle}</p>
            </div>
          )}
          {isUpload && uploadedImage && (
            <div className='flex flex-col items-center justify-center p-4'>
              <button
                className='-mb-6 mt-2 cursor-pointer rounded-lg bg-main px-20 py-1.5 text-white'
                onClick={handleButtonClick}
              >
                {t('MSG_144')}
              </button>
              <input
                type='file'
                className='hidden'
                ref={fileInputRef}
                onChange={handleImageUpload}
              />
            </div>
          )}
        </div>
        <div className='flex flex-1 justify-center py-4'>
          {onButtonClick && buttonText && (
            <button
              className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
              onClick={onButtonClick}
            >
              {buttonText}
              <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
                <img
                  src={estrella}
                  alt='Star image'
                  className='ml-1 h-4 w-4'
                />
                <p className='ml-1 text-white'>1</p>
              </div>
            </button>
          )}
        </div>
      </div>
      <IncidentModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        message={modalMessage}
        onCloseNavigate={closeModal}
      />
    </div>
  )
}
